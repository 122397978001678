

// This has scope for *one* mediaset per page
let vidset = document.querySelector('.mediaset__list[data-ms]');
let msParent;  // Will store the parent element of `vidset`;
let msArea;    // Will store videt's constructed `.player-area`
let playerLoc; // Will track where a `.mediaset` player is
let playBtns;  // Will store the play buttons for the `.mediaset`
let rarr;      // Will store the right pointing svg arrow
let chapter;   // Will store whether a mediaset chapter is active
let wideX;     // Will store the threshold at which the `.player-area` should be moved


/*
These get instantiated per mediaset and/or regular single embedded video:
let area, playerWrap;
*/


// Instantiates a multi-video mediaset with a shared player area
const initVidSet = () => {
    if ( !vidset ) { return; }
    
    const msName = vidset.getAttribute('data-ms');
    wideX        = vidset.getAttribute('data-wide')
    msParent     = vidset.parentElement;
    const links  = vidset.querySelectorAll('a');
    const paras  = vidset.querySelectorAll('p');

    paras.forEach(function(para) {
        let text = para.textContent;
        para.textContent = text.replace(/^([^:]+)([:|\s+])?$/, '$1');
    });

    // Format the prompts
    links.forEach(function(link) {
        const linkType = link.getAttribute('data-type');
        link.querySelector('span').classList.add('hidden');

        if ( linkType === 'video' ) {
            const dur = link.getAttribute('data-duration');
            const btn = `<button class="play btn" data-duration="${dur}" data-url="${link.href}">${link.innerHTML}</button>`;
            link.parentElement.innerHTML = btn;
        }
        else {
            link.classList.add('read', 'btn');
        }
    });

    // Get all play buttons 
    playBtns = document.querySelectorAll('.play.btn');

    // Add a rarrowhead
    rarr = document.createElement('div');
    rarr.id = 'rarr';
    rarr.innerHTML = '<svg viewBox="0 0 32 90" preserveAspectRatio="none"><polygon class="rarr-line" points="32 45 0 90 0 0 32 45"/><polygon class="rarr-fill" points="31 45 -1 90 -1 0 31 45"/></svg>';
    msParent.appendChild(rarr);

    // Add an iframe player
    const iframe = vidIframe();
    msArea = document.createElement('div');
    msArea.innerHTML = `<div class="player-wrap ratio-16-9">${iframe}</div>`;
    msArea.classList.add('player-area');

    msParent.appendChild(msArea);
    msParent.classList.add('mediaset');
    msParent.setAttribute('data-ms', msName);
    vidset.removeAttribute('data-ms');

    // Tell the HTML this has been done
    vidset.setAttribute('data-iframed', true);
}


// Returns a video iframe (currently YouTube assumed)
const vidIframe = (url, ratio) => {
    url   = url ? url : '';
    ratio = ratio ? ratio : '16 / 9';

    const html = `<iframe 
        style="aspect-ratio: ${ratio}" 
        allowfullscreen="true" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
        frameborder="0" id="primer-player" height="100%" src="${url}" width="100%"></iframe>`;

    return html;
}


// Converts <a href="url" data-video="ratio">text</a> into an embed iframe with a .big-play button overlay
const embedVideos = () => {
    const videoElems = document.querySelectorAll('[data-video]');

    if ( !videoElems ) { return; }

    videoElems.forEach(function(elem) {
        const area   = elem.parentElement;
        const text   = elem.textContent;
        const ratio  = elem.getAttribute('data-video');
        const poster = elem.getAttribute('data-poster');
        let url = elem.href;
        url = embedUrl(url, true);
        const iframe = vidIframe(null, ratio);
        area.innerHTML = iframe;

        if ( poster ) {
            area.style.backgroundImage = `url('${poster}')`;
        }

        addBigPlay(area, text, url);
    });
}


// Adds a big play button to a .player-area
const addBigPlay = (area, text, url) => {
    const btn = document.createElement('button');
    btn.classList.add('big-play');
    btn.innerHTML = `<b></b><span>${text}</span>`;
    btn.setAttribute('data-url', url);
    area.appendChild(btn);
}


// Returns a video embed URL
const embedUrl = (url, autoplay) => {
    autoplay = autoplay ? 'autoplay=1&' : '';

    if ( url.includes('youtu') ) {
        if ( !url.includes('/embed/') ) {
            // Check for https://www.youtube.com/watch?v=id
            const ytComPatt = /.*watch\?([^=]+)?v=([^&]+)(.*)?$/;
            url = ytComPatt.test(url) ? url.replace(ytComPatt, 'https://www.youtube.com/embed/$2') : url;
    
            // Check for https://youtu.be/id
            const ytBePatt = /.*\.be\/([^\?|\/]+)(\?.*)?$/;
            url = ytBePatt.test(url) ? url.replace(ytBePatt, 'https://www.youtube.com/embed/$1') : url;
    
            url = `${url}?${autoplay}modestbranding=1`;
        }
        else {
            // Strip potentially unwanted params
            // url = url.replace(/^([^\?]+)(\?.*)?$/, '$1');
        }
    }
    else {
        url = '';
        let msg = `Not YouTube: ${url}`;
        console.log(msg);
    }

    return url;
}


// Plays a video in a multi-video `.mediaset` or a single video embed
const playVideo = (btn) => {
    const state = btn.getAttribute('aria-selected') === 'true';

    if ( state ) {
        return;
    }

    let mediaset;
    let area = btn.closest('.player-area');

    if ( !area ) {
        // This must be a button in `msParent` so find its mediaset
        mediaset = btn.closest('.mediaset[data-ms]');
        area     = mediaset ? mediaset.querySelector('.player-area') : null;
    }

    const iframe = area ? area.querySelector('iframe') : null;

    if ( iframe ) {
        let url = btn.getAttribute('data-url');
        url = url ? embedUrl(url, true) : null;

        if ( !url ) {
            const msg = `No URL for ${btn.textContent}`;
            console.log(msg);
            return;
        }

        if ( mediaset ) {
            chapter = btn.closest('[id]');
            placePlayer(chapter, area, mediaset);

            playBtns.forEach(function(elem) {
                if ( elem !== btn ) {
                    elem.removeAttribute('aria-selected');
                    elem.closest('[id]').classList.remove('selected');
                }
            });

            const playerWrap = area.querySelector('.player-wrap');

            btn.setAttribute('aria-selected', 'true');
            chapter.classList.add('selected');
            chapter.appendChild(rarr);
            btn.closest('.mediaset__list').classList.add('has-selection');
            playerWrap.classList.add('has-src');
        }
        else {
            // Remove the overlay button now the iframe has a source
            btn.remove();
        }

        iframe.setAttribute('src', url);
    }
    else {
        console.log('No iframe implemented');
    }
}


// Moves a multi-video mediaset player into the right context subject to viewport width
const placePlayer = (chapter, area, mediaset) => {
    const winX = Math.floor( window.innerWidth );

    if ( winX < wideX) {
        chapter.appendChild(area);
        playerLoc = chapter;
        const next = chapter.nextElementSibling;

        if ( next ) {
            // console.log(next);

            setTimeout(function() {
                next.scrollIntoView({ block: 'end' });
            }, 300);
        }
    }
    else {
        mediaset.appendChild(area);
        playerLoc = mediaset;
    }
}



// Observe `figure.full-width` in relation to `.article__toc .toc`
let tocNav, tocY;

const observeFullWidth = (elem) => {
    let options = {
        root: null,
        rootMargin: '0px',
        threshold: obsThresholds(20)
    };
    
    const observer = new IntersectionObserver(tocOpacity, options);
    observer.observe(elem);
}

const tocOpacity = (entries) => {
    entries.forEach((entry) => {
        const winX  = window.innerWidth;

        if ( winX >= 1140 ) {
            const eTop  = entry.boundingClientRect.top;
            const eBase = entry.boundingClientRect.bottom;
            let opacity = eTop > tocY || eBase < 0 ? 1 : 0;
            tocNav.style.opacity = opacity;
        }
        else {
            tocNav.style.opacity = 1;
        }
    });
}


// Initialise the handling of full width images
const initFullWidths = () => {
    const fullWidths  = document.querySelectorAll('.article__body .full-width');
    tocNav = document.querySelector('.article__toc .toc');
    
    if ( fullWidths && tocNav ) {  
        tocY = tocNav.offsetHeight + 80;
 
        fullWidths.forEach(function(elem) {   
            elem.setAttribute('data-observed', 'true');      
            observeFullWidth(elem);
        });
    }
}


// Create an array of observer thresholds
const obsThresholds = (steps) => {
    let thresholds = [];
    
    for (let i = 1.0; i <= steps; i++) {
        let ratio = i / steps;
        thresholds = thresholds.concat(ratio);
    }
    
    thresholds = thresholds.concat(0);
    return thresholds;
}
      



/***** Run *****/

initVidSet();
embedVideos();
initFullWidths();


// Click events

document.addEventListener('click', function(event) {
    const trigger = event.target;

    if ( trigger.closest('[data-url]') ) {
        const btn = trigger.closest('[data-url]');
        playVideo(btn);
    }
});


// Window resize management

let count = 0;
let throttleCount = 0;
let wait = false;

window.addEventListener('resize', function() {
	count++;
	if (wait) return;
	throttleCount++;

    wait = true;
	setTimeout(function() {
		wait = false;

        const winX = Math.floor( window.innerWidth );
        const hasPlayer = chapter ? chapter.querySelector('.player-area') !== null : false;
    
        if ( chapter && playerLoc !== msParent && winX > wideX && hasPlayer ) {
            // Move player into parent
            msParent.appendChild(msArea);
            playerLoc = msParent;
        }
    
        if ( chapter && playerLoc !== chapter && winX < wideX && !hasPlayer ) {
            // Move player into chapter
            chapter.appendChild(msArea);
            playerLoc = chapter;
        }
	}, 300);
});
